<template>
  <b-modal
    id="settingsItem"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    :cancel-title="t('GLOBAL.CANCEL')"
    :ok-title="okTitle"
    @ok="saveItem"
    @close="resetModal"
    @cancel="resetModal"
  >
    <template slot="modal-title">
      <span class="text-success" v-if="is_new"
        ><i class="fa fa-plus"></i> Yeni Ayar Ekle
      </span>
      <span class="text-danger" v-else
        ><i class="fa fa-pen-alt"></i> Düzenle | {{ item.label }}
      </span>
    </template>
    <b-form-group label-cols="3" label="Etiket">
      <b-input
        type="text"
        v-model="item.label"
        required
        placeholder="Etiket adını giriniz"
      ></b-input>
    </b-form-group>

    <b-form-group
      label-cols="3"
      label="Anahtar"
      description="Daha önce kullanılmamış anahtar kelime girmelisiniz"
    >
      <b-input
        type="text"
        v-model="item.key"
        required
        placeholder="Settings Key"
      ></b-input>
    </b-form-group>

    <b-form-group label-cols="3" label="Açıklama">
      <b-input
        type="text"
        v-model="item.desc"
        required
        placeholder="Açıklama"
      ></b-input>
    </b-form-group>

    <b-form-group label-cols="3" label="Input Tipi">
      <b-form-radio-group
        size="sm"
        v-model="item.type"
        :options="input_types"
        name="type"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      label-cols="3"
      label="Data Tipi"
      v-if="
        item.type == 'select' || item.type == 'checkbox' || item.type == 'radio'
      "
    >
      <b-form-radio-group
        size="sm"
        v-model="item.data_type"
        :options="data_types"
        name="data_type"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      label-cols="3"
      label="Seçenek Gir"
      v-if="
        (item.type == 'select' ||
          item.type == 'checkbox' ||
          item.type == 'radio') &&
          item.data_type == 'manuel_options'
      "
    >
      <b-input-group>
        <b-input type="text" v-model="option.text" placeholder="Text"></b-input>
        <b-input
          type="text"
          v-model="option.value"
          placeholder="Value"
        ></b-input>
        <b-input-group-append>
          <b-button variant="success" @click="addOption" v-if="!update_option"
            ><i class="fa fa-plus"></i
          ></b-button>
          <b-button variant="danger" @click="resetValues" v-if="update_option"
            ><i class="fa fa-save"></i
          ></b-button>
        </b-input-group-append>
      </b-input-group>

      <v-simple-table fixed-header height="300px" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Text</th>
              <th class="text-left">Value</th>
              <th class="text-left" width="100"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="option in item.options" :key="option.name">
              <td>{{ option.text }}</td>
              <td>{{ option.value }}</td>
              <td>
                <v-btn
                  x-small
                  color="primary"
                  class="mr-1"
                  @click="editOption(props.row)"
                  ><i class="fa fa-pen"></i
                ></v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="deleteOption(props.row.originalIndex)"
                  ><i class="fa fa-times"></i
                ></v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </b-form-group>

    <b-form-group
      label-cols="3"
      label="Data Seç"
      v-if="
        (item.type == 'select' ||
          item.type == 'checkbox' ||
          item.type == 'radio') &&
          item.data_type == 'automatic_options'
      "
    >
      {{ Object.keys(group.value.settings_data) }}
      <b-form-radio-group
        size="sm"
        v-model="item.data_automatic"
        :options="Object.keys(group.value.settings_data)"
        name="data_automatic"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      label-cols="3"
      label="Fonksiyon Adı Gir"
      v-if="
        (item.type == 'select' ||
          item.type == 'checkbox' ||
          item.type == 'radio') &&
          item.data_type == 'function_options'
      "
    >
      <b-input
        type="text"
        v-model="item.function_name"
        description="Bu alana data sağlayacak fonksiyonun adını giriniz."
        placeholder="Fonksiyon adı"
      ></b-input>
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  props: {
    setting_index: {
      type: Number,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    old_settings: {
      type: Array,
      required: true
    },
    group: {
      type: Object,
      required: true
    },
    is_new: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      systemIcons: null,
      input_types: [
        { text: "Text", value: "text" },
        { text: "Textarea", value: "textarea" },
        { text: "Number", value: "number" },
        { text: "Email", value: "email" },
        { text: "Password", value: "password" },
        { text: "Checkbox", value: "checkbox" },
        { text: "Radio", value: "radio" },
        { text: "Tag", value: "tag" },
        { text: "Select", value: "select" }
      ],
      data_types: [
        { text: "Elle Gir", value: "manuel_options" },
        { text: "Alan Seç", value: "automatic_options" },
        { text: "Fonksiyon Adı", value: "function_options" }
      ],
      options_columns: [
        {
          label: "text",
          field: "text",
          thClass: "text-nowrap",
          tdClass: "text-nowrap"
        },
        {
          label: "value",
          field: "value",
          thClass: "text-nowrap",
          tdClass: "text-nowrap"
        },
        {
          label: "Seçenekler",
          field: "link",
          tdClass: "",
          thClass: "",
          width: "15%"
        }
      ],
      option: {
        text: null,
        value: null
      },
      update_option: false
    };
  },
  methods: {
    resetModal() {
      this.resetValues();
      this.$emit("cancelItem", this.old_settings);
    },
    addOption() {
      this.item.options.push(this.option);
      this.option = { text: null, value: null };
    },
    editOption(item) {
      this.update_option = true;
      this.option = item;
    },
    deleteOption(index) {
      if (!confirm("element_option_delete_confirm")) return false;
      let newOptions = this.item.options
        .slice(0, index)
        .concat(this.item.options.slice(index + 1, this.item.options.length));
      this.item.options = newOptions;
    },
    saveItem() {
      if (this.is_new) {
        this.group.value.settings_fields.push(this.item);
        if (
          this.item.type == "select" ||
          this.item.type == "checkbox" ||
          this.item.type == "radio" ||
          this.item.type == "tag"
        ) {
          this.group.value.settings_data[this.item.key] = [];
        } else {
          this.group.value.settings_data[this.item.key] = null;
        }
      }
      this.updateItem();
      this.resetValues();
    },
    updateItem() {},
    resetValues() {
      this.update_option = false;
      this.option = { text: null, value: null };
    }
  },
  computed: {
    okTitle() {
      return this.is_new ? this.t("GLOBAL.ADD") : this.t("GLOBAL.SAVE");
    }
  }
};
</script>
