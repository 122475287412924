var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"settingsItem","size":"lg","no-close-on-backdrop":"","no-close-on-esc":"","cancel-title":_vm.t('GLOBAL.CANCEL'),"ok-title":_vm.okTitle},on:{"ok":_vm.saveItem,"close":_vm.resetModal,"cancel":_vm.resetModal}},[_c('template',{slot:"modal-title"},[(_vm.is_new)?_c('span',{staticClass:"text-success"},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Yeni Ayar Ekle ")]):_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-pen-alt"}),_vm._v(" Düzenle | "+_vm._s(_vm.item.label)+" ")])]),_c('b-form-group',{attrs:{"label-cols":"3","label":"Etiket"}},[_c('b-input',{attrs:{"type":"text","required":"","placeholder":"Etiket adını giriniz"},model:{value:(_vm.item.label),callback:function ($$v) {_vm.$set(_vm.item, "label", $$v)},expression:"item.label"}})],1),_c('b-form-group',{attrs:{"label-cols":"3","label":"Anahtar","description":"Daha önce kullanılmamış anahtar kelime girmelisiniz"}},[_c('b-input',{attrs:{"type":"text","required":"","placeholder":"Settings Key"},model:{value:(_vm.item.key),callback:function ($$v) {_vm.$set(_vm.item, "key", $$v)},expression:"item.key"}})],1),_c('b-form-group',{attrs:{"label-cols":"3","label":"Açıklama"}},[_c('b-input',{attrs:{"type":"text","required":"","placeholder":"Açıklama"},model:{value:(_vm.item.desc),callback:function ($$v) {_vm.$set(_vm.item, "desc", $$v)},expression:"item.desc"}})],1),_c('b-form-group',{attrs:{"label-cols":"3","label":"Input Tipi"}},[_c('b-form-radio-group',{attrs:{"size":"sm","options":_vm.input_types,"name":"type"},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})],1),(
      _vm.item.type == 'select' || _vm.item.type == 'checkbox' || _vm.item.type == 'radio'
    )?_c('b-form-group',{attrs:{"label-cols":"3","label":"Data Tipi"}},[_c('b-form-radio-group',{attrs:{"size":"sm","options":_vm.data_types,"name":"data_type"},model:{value:(_vm.item.data_type),callback:function ($$v) {_vm.$set(_vm.item, "data_type", $$v)},expression:"item.data_type"}})],1):_vm._e(),(
      (_vm.item.type == 'select' ||
        _vm.item.type == 'checkbox' ||
        _vm.item.type == 'radio') &&
        _vm.item.data_type == 'manuel_options'
    )?_c('b-form-group',{attrs:{"label-cols":"3","label":"Seçenek Gir"}},[_c('b-input-group',[_c('b-input',{attrs:{"type":"text","placeholder":"Text"},model:{value:(_vm.option.text),callback:function ($$v) {_vm.$set(_vm.option, "text", $$v)},expression:"option.text"}}),_c('b-input',{attrs:{"type":"text","placeholder":"Value"},model:{value:(_vm.option.value),callback:function ($$v) {_vm.$set(_vm.option, "value", $$v)},expression:"option.value"}}),_c('b-input-group-append',[(!_vm.update_option)?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.addOption}},[_c('i',{staticClass:"fa fa-plus"})]):_vm._e(),(_vm.update_option)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.resetValues}},[_c('i',{staticClass:"fa fa-save"})]):_vm._e()],1)],1),_c('v-simple-table',{attrs:{"fixed-header":"","height":"300px","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Text")]),_c('th',{staticClass:"text-left"},[_vm._v("Value")]),_c('th',{staticClass:"text-left",attrs:{"width":"100"}})])]),_c('tbody',_vm._l((_vm.item.options),function(option){return _c('tr',{key:option.name},[_c('td',[_vm._v(_vm._s(option.text))]),_c('td',[_vm._v(_vm._s(option.value))]),_c('td',[_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.editOption(_vm.props.row)}}},[_c('i',{staticClass:"fa fa-pen"})]),_c('v-btn',{attrs:{"x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteOption(_vm.props.row.originalIndex)}}},[_c('i',{staticClass:"fa fa-times"})])],1)])}),0)]},proxy:true}],null,false,1416617220)})],1):_vm._e(),(
      (_vm.item.type == 'select' ||
        _vm.item.type == 'checkbox' ||
        _vm.item.type == 'radio') &&
        _vm.item.data_type == 'automatic_options'
    )?_c('b-form-group',{attrs:{"label-cols":"3","label":"Data Seç"}},[_vm._v(" "+_vm._s(Object.keys(_vm.group.value.settings_data))+" "),_c('b-form-radio-group',{attrs:{"size":"sm","options":Object.keys(_vm.group.value.settings_data),"name":"data_automatic"},model:{value:(_vm.item.data_automatic),callback:function ($$v) {_vm.$set(_vm.item, "data_automatic", $$v)},expression:"item.data_automatic"}})],1):_vm._e(),(
      (_vm.item.type == 'select' ||
        _vm.item.type == 'checkbox' ||
        _vm.item.type == 'radio') &&
        _vm.item.data_type == 'function_options'
    )?_c('b-form-group',{attrs:{"label-cols":"3","label":"Fonksiyon Adı Gir"}},[_c('b-input',{attrs:{"type":"text","description":"Bu alana data sağlayacak fonksiyonun adını giriniz.","placeholder":"Fonksiyon adı"},model:{value:(_vm.item.function_name),callback:function ($$v) {_vm.$set(_vm.item, "function_name", $$v)},expression:"item.function_name"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }