<template>
  <b-modal
    id="settingsGroup"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    lazy
    static
    cancel-title="İptal"
    ok-title="Kaydet"
    @ok="saveSettings"
    @cancel="resetModal"
  >
    <template slot="modal-title">
      <span class="text-success" v-if="is_new"
        ><i class="fa fa-plus"></i> {{ t("GLOBAL.ADD") }}
      </span>
      <span class="text-danger" v-else
        ><i class="fa fa-pen-alt"></i>
        {{ t("GLOBAL.EDIT", { name: t("GLOBAL.PANELSETTINGS") }) }}
      </span>
    </template>
    <div v-if="group.hasOwnProperty('name')">
      <b-form-group label-cols="3" :label="t('GLOBAL.NAME')">
        <b-input type="text" v-model="group.name"></b-input>
      </b-form-group>
      <b-form-group label-cols="3" :label="t('GLOBAL.KEY')">
        <b-input type="text" v-model="group.value.settings_key"></b-input>
      </b-form-group>

      <b-form-group label-cols="3" :label="t('GLOBAL.DESCRIPTION')">
        <b-input type="text" v-model="group.value.desc"></b-input>
      </b-form-group>

      <b-form-group
        label-cols="3"
        :label="t('GLOBAL.ICON')"
        description="Fontawesome / MDI / LineIcon"
      >
        <b-input type="text" v-model="group.value.icon"></b-input>
      </b-form-group>

      <b-form-group label-cols="3" label="">
        <b-form-checkbox
          v-model="group.value.status"
          name="status"
          switch
          inline
          >{{ t("GLOBAL.ACTIVE") }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label-cols="3" :label="t('GLOBAL.REORDER')">
        <draggable
          tag="div"
          :list="group.value.settings_fields"
          class="widget-list widget-list-rounded"
          handle=".handle"
          v-if="!is_new"
        >
          <div
            class="widget-list-item"
            v-for="field in group.value.settings_fields"
            :key="field.key"
          >
            <div class="widget-list-media icon">
              <i :class="field.icon" class="bg-yellow text-inverse"></i>
            </div>
            <div class="widget-list-content">
              <h4 class="widget-list-title">{{ field.label }}</h4>
            </div>
            <div
              class="widget-list-action text-nowrap text-grey-darker text-right"
            >
              <i
                class="fa fa-align-justify handle text-muted t-plus-1 fa-lg m-l-5"
              ></i>
            </div>
          </div>
        </draggable>
      </b-form-group>

      <hr />

      <v-btn color="error" small @click="deleteGroup" v-if="!is_new">
        <v-icon x-small class="mr-1">fa fa-trash-alt</v-icon>
        {{ t("GLOBAL.DELETE") }}</v-btn
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true
    },
    old_settings: {
      type: Array,
      required: true
    },
    is_new: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      systemIcons: null
    };
  },
  methods: {
    resetModal() {
      this.$emit("cancelGroup", this.old_settings);
    },
    saveSettings() {},
    deleteGroup() {
      this.popConfirm(this.t("GLOBAL.DELETE"), "warning").then(() => {
        this.$emit("deleteGroup", this.group);
      });
    }
  }
};
</script>

<style>
.widget-list {
  font-size: 12px;
}
</style>
