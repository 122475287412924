<template>
  <v-app>
    <b-col cols="12" class="mt-3">
      <v-btn color="error" small @click="updateSetting">
        <v-icon x-small class="mr-1">fa fa-save</v-icon>
        {{ t("GLOBAL.SAVE") }}
      </v-btn>

      <v-btn
        color="warning"
        small
        @click="reOrderSettings"
        class="pull-right ml-5"
      >
        <v-icon x-small class="mr-1">fa fa-sort-numeric-up</v-icon>
        {{ t("GLOBAL.REORDER") }}
      </v-btn>

      <v-btn
        color="primary"
        small
        @click="addNewSettingGroup"
        class="pull-right"
      >
        <v-icon x-small class="mr-1">fa fa-plus</v-icon>
        {{ t("GLOBAL.NEW", { name: t("GLOBAL.SETTINGS") }) }}
      </v-btn>

      <v-card v-if="settings" class="mt-3">
        <v-tabs
          v-model="tab"
          background-color="primary accent-4"
          dark
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(setting, i) in settings"
            :key="'tab-' + i"
            :active="setting.value.settings_key == 'google_analytic'"
          >
            {{ setting.name }}
            <v-icon v-if="setting.value.icon">{{ setting.value.icon }}</v-icon>
          </v-tab>

          <v-tab-item v-for="(setting, i) in settings" :key="'tab-item-' + i">
            <b-container fluid>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    v-for="(field, field_index) in setting.value
                      .settings_fields"
                    :key="field.key + '_' + field_index + '_' + setting.id"
                    label-cols="3"
                    :description="field.desc"
                  >
                    <template slot="label">
                      <v-btn
                        color="primary"
                        x-small
                        v-if="is_update"
                        class="edit-buttons pull-right ml-2"
                        @click="editItem(setting, field)"
                        ><i class="fa fa-pen"></i>
                      </v-btn>
                      <v-btn
                        color="error"
                        x-small
                        v-if="is_update"
                        class="edit-buttons pull-right m-r-3"
                        @click="deleteItem(setting, field_index)"
                        ><i class="fa fa-trash"></i>
                      </v-btn>

                      <i v-if="field.icon" :class="field.icon"></i>
                      {{ field.label }}<br />
                      <strong class="text-success"
                        ><small>{{ field.key }}</small></strong
                      >
                      <i
                        class="fa fa-question-circle"
                        v-if="field.tooltip"
                        v-b-popover.hover.right="field.tooltip"
                      ></i>
                    </template>

                    <b-input
                      v-if="
                        field.type == 'text' ||
                          field.type == 'email' ||
                          field.type == 'number' ||
                          field.type == 'password'
                      "
                      :type="field.type"
                      v-model="setting.value.settings_data[field.key]"
                    ></b-input>

                    <b-textarea
                      v-model="setting.value.settings_data[field.key]"
                      v-if="field.type == 'textarea'"
                    ></b-textarea>

                    <b-form-checkbox-group
                      size="sm"
                      v-model="setting.value.settings_data[field.key]"
                      :options="options(setting, field)"
                      v-if="field.type == 'checkbox'"
                      switches
                    ></b-form-checkbox-group>

                    <b-form-radio-group
                      size="sm"
                      v-model="setting.settings_data[field.key]"
                      :options="options(setting, field)"
                      v-if="field.type == 'radio'"
                      switches
                    ></b-form-radio-group>

                    <input-tag
                      v-if="field.type == 'tag'"
                      :key="field.key"
                      v-model="setting.settings_data[field.key]"
                      class="form-control white"
                    ></input-tag>

                    <v-select
                      v-if="field.type == 'select'"
                      v-model="setting.settings_data[field.key]"
                      label="text"
                      :options="options(setting, field)"
                    ></v-select>
                  </b-form-group>

                  <b-form-group class="mt-5" v-if="!is_update">
                    <v-btn
                      color="primary"
                      small
                      class="pull-right ml-2"
                      @click="addNewSettingItem(setting)"
                      ><v-icon x-small class="mr-1">fa fa-plus</v-icon> Yeni
                      Ayar Ekle</v-btn
                    >
                    <v-btn
                      color="success"
                      small
                      class="pull-right ml-2"
                      @click="editSettingGroup(setting, i)"
                      ><v-icon x-small class="mr-1">fa fa-pen</v-icon> Grubu
                      Düzenle</v-btn
                    >
                    <v-btn
                      color="primary"
                      small
                      class="pull-right ml-2"
                      @click="is_update = true"
                      ><v-icon x-small class="mr-1">fa fa-flag</v-icon>
                      Düzenlemeyi Başlat</v-btn
                    >
                  </b-form-group>

                  <b-form-group class="m-t-30" v-if="is_update">
                    <v-btn
                      color="error"
                      small
                      class="pull-right m-r-3"
                      @click="is_update = false"
                      ><v-icon x-small class="mr-1">fa fa-times</v-icon>
                      Düzenlemeyi Bitir</v-btn
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </b-col>

    <ModalSettingsGroup
      :is_new="is_new"
      :group="group"
      :old_settings="old_settings"
      :setting_index="setting_index"
      @cancelGroup="cancelGroup"
      @refreshData="refreshData"
      @deleteGroup="deleteGroup"
    />
    <ModalSettingsItem
      :is_new="is_new"
      :group="group"
      :item="item"
      :old_settings="old_settings"
      :field_index="field_index"
      @cancelItem="cancelItem"
    />
    <ModalSettingsOrder
      :old_settings="old_settings"
      :settings="settings"
      @refreshData="refreshData"
      @saveAllSettings="updateSetting"
      @cancelGroup="cancelGroup"
    />
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.store";
import ModalSettingsGroup from "@/components/settings/ModalSettingsGroup";
import ModalSettingsItem from "@/components/settings/ModalSettingsItem";
import ModalSettingsOrder from "@/components/settings/ModalSettingsOrder";

export default {
  name: "PanelSettings",
  components: { ModalSettingsGroup, ModalSettingsItem, ModalSettingsOrder },
  data() {
    return {
      tab: null,
      id: this.$route.params.id,
      breadcrumbs: [{ page: "Settings" }, { page: "General Settings" }],
      pageTitle: "Settings",
      smallTitle: "General Settings",
      is_new: true,
      is_update: false,
      setting_index: null,
      field_index: null,
      group: {},
      item: {},
      old_settings: [],
      settings: [],
      setting: null
    };
  },
  methods: {
    reOrderSettings() {
      this.$bvModal.show("settingsOrder");
    },
    refreshData() {
      this.setting_index = null;
      this.getSetting();
    },
    cancelGroup() {
      // this.settings = event;
    },
    cancelItem(event) {
      this.settings = event;
    },
    deleteGroup(index) {
      this.settings.splice(index, 1);
      this.setting_index = null;
      this.$bvModal.hide("settingsGroup");
      this.updateSetting();
    },
    editItem(group, item) {
      this.is_new = false;
      this.item = item;
      this.group = group;
      this.old_settings = JSON.parse(JSON.stringify(this.settings));
      this.$bvModal.show("settingsItem");
    },
    deleteItem(group, index) {
      this.popConfirm(this.t("GLOBAL.DELETE"), "warning").then(() => {
        delete group.value.settings_data[
          group.value.settings_fields[index].key
        ];
        let newOptions = group.value.settings_fields
          .slice(0, index)
          .concat(
            group.value.settings_fields.slice(
              index + 1,
              group.value.settings_fields.length
            )
          );
        group.value.settings_fields = newOptions;
      });
    },
    editSettingGroup(group, index) {
      this.is_new = false;
      this.group = group;
      this.setting_index = index;
      this.old_settings = JSON.parse(JSON.stringify(this.settings));
      this.$bvModal.show("settingsGroup");
    },
    getSetting() {
      this.settings = [];
      this.apiSend("get", `settings/` + this.id)
        .then(item => {
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.t("GLOBAL.PANELSETTINGS")
            },
            {
              title: item.name.toUpperCase()
            }
          ]);
          this.setting = item;
          this.settings = JSON.parse(item.value);
        })
        .catch(() => {});
    },
    addNewSettingGroup() {
      this.is_new = true;
      this.old_settings = JSON.parse(JSON.stringify(this.settings));
      this.group = {
        name: this.t("GLOBAL.NEW", { name: this.t("GLOBAL.SETTINGS") }),
        value: {
          settings_key: "",
          order_no: this.settings.length + 1,
          name: "",
          desc: "",
          icon: "",
          settings_data: {},
          settings_fields: [],
          status: true,
          options_order: null
        }
      };
      this.settings.push(this.group);
      this.$bvModal.show("settingsGroup");
    },
    addNewSettingItem(group) {
      this.group = group;
      this.is_new = true;
      this.old_settings = JSON.parse(JSON.stringify(this.settings));
      this.item = {
        key: "",
        order_no: 99,
        name: "",
        desc: "",
        icon: "",
        type: "",
        options: []
      };
      this.$bvModal.show("settingsItem");
    },
    updateSetting() {
      let updateData = {
        id: this.setting.id,
        name: this.setting.name,
        value: JSON.stringify(this.settings)
      };
      this.apiSend("put", `settings/` + this.id, updateData)
        .then(() => {
          this.popAlert(
            this.t("MESSAGES.UPDATED") +
              "<br>" +
              this.setting.name +
              "[" +
              this.setting.id +
              "]",
            "success"
          );
        })
        .catch(() => {});
    },
    options(group, field) {
      if (field.data_type == "automatic_options") {
        return group.settings_data[field.data_automatic];
      }
      if (
        field.data_type == "function_options" &&
        this.hasOwnProperty(field.function_name)
      ) {
        return this[field.function_name]();
      }

      if (field.data_type == "manuel_options") {
        return field.options;
      }
    }
  },
  created() {
    this.getSetting();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.t("GLOBAL.PANELSETTINGS")
      }
    ]);
  }
};
</script>

<style>
.widget-list .widget-list-item {
  display: table;
  width: 100%;
  background: #fff;
  text-decoration: none;
  color: #2d353c;
}
.widget-list .widget-list-item > div:first-child {
  padding-left: 15px;
}
.widget-list .widget-list-item > div:last-child {
  padding-right: 15px;
}
.widget-list .widget-list-item + .widget-list-item .widget-list-action,
.widget-list .widget-list-item + .widget-list-item .widget-list-content {
  border-top: 1px solid #e4e7ea;
}
.widget-list .widget-list-item .widget-list-media {
  width: 70px;
  height: 60px;
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
}
.widget-list .widget-list-item .widget-list-media + div {
  padding-left: 0;
}
.widget-list .widget-list-item .widget-list-media.icon {
  width: 30px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.widget-list .widget-list-item .widget-list-media.icon i {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.widget-list .widget-list-item .widget-list-media img {
  max-width: 100%;
  max-height: 100%;
}
.widget-list .widget-list-item .widget-list-content {
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
}
.widget-list .widget-list-item .widget-list-content + div {
  padding-left: 0;
}
.widget-list .widget-list-item .widget-list-action {
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
  width: 10%;
  padding-left: 15px;
  text-align: center;
}
.widget-list .widget-list-item .widget-list-action + div {
  padding-left: 0;
}
.widget-list .widget-list-item .widget-list-title {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}
.widget-list .widget-list-item .widget-list-desc {
  font-size: 11px;
  margin: 0;
  color: #6f8293;
}
.widget-list.widget-list-rounded .widget-list-item:first-child,
.widget-list.widget-list-rounded.widget-list-item:first-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}
.widget-list.widget-list-rounded .widget-list-item:last-child,
.widget-list.widget-list-rounded.widget-list-item:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.inverse-mode .widget-list-item,
.inverse-mode.widget-list-item {
  background: #2d353c;
}
.inverse-mode .widget-list-item + .widget-list-item .widget-list-action,
.inverse-mode .widget-list-item + .widget-list-item .widget-list-content,
.inverse-mode.widget-list-item + .widget-list-item .widget-list-action,
.inverse-mode.widget-list-item + .widget-list-item .widget-list-content {
  border-color: #3d4852;
}
.inverse-mode .widget-list-item .widget-list-title,
.inverse-mode.widget-list-item .widget-list-title {
  color: #fff;
}
.inverse-mode .widget-list-item .widget-list-desc,
.inverse-mode.widget-list-item .widget-list-desc {
  color: #c6ced5;
}
.widget-list .widget-list-item .widget-list-media.icon {
  width: 30px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.widget-list .widget-list-item .widget-list-media.icon i {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
</style>
