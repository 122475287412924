<template>
  <b-modal
    id="settingsOrder"
    no-close-on-backdrop
    no-close-on-esc
    lazy
    static
    cancel-title="İptal"
    ok-title="Kaydet"
    @ok="saveAllSettings"
    @close="resetModal"
    @cancel="resetModal"
  >
    <template slot="modal-title">
      <span class="text-success"
        ><i class="fa fa-sort-numeric-down"></i> Ayar Gruplarını Sırala
      </span>
    </template>
    <p>
      Sıralamak için ikonundan tutup taşıyınız.Varsayılan pencereyi
      işaretleyiniz.
    </p>
    <draggable
      tag="div"
      :list="settings"
      class="widget-list widget-list-rounded"
      handle=".handle"
    >
      <div
        class="widget-list-item"
        v-for="setting in settings"
        :key="setting.id"
      >
        <div class="widget-list-content">
          <h4 class="widget-list-title">{{ setting.name }}</h4>
        </div>
        <div class="widget-list-action text-nowrap text-grey-darker text-right">
          <i
            class="fa fa-align-justify handle text-muted t-plus-1 fa-lg m-l-5"
          ></i>
        </div>
      </div>
    </draggable>
  </b-modal>
</template>

<script>
export default {
  props: {
    settings: {
      type: Array,
      required: true
    },
    old_settings: {
      type: Array,
      required: true
    }
  },
  methods: {
    resetModal() {
      this.$emit("cancelGroup", this.old_settings);
    },
    saveAllSettings() {
      this.$emit("saveAllSettings");
    }
  }
};
</script>
